<template>
    <div class="p-4">
        <top-search></top-search>

        <div class="text-2xl text-left p-4 font-normal">
            Dashboard
        </div>

        <div class="p-4 md:flex md:justify-between md:flex-wrap md:items-center">            
            <div class="min-w-sm p-4 my-5 mx-auto md:my-5 bg-white rounded-lg shadow-md">                
                <div class="flex items-center justify-between mb-2">
                    <span class="text-md font-normal text-brown-default">Users</span>
                    <span class="bg-red-100 text-brown-default text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-16">
                        <svg class="w-4 h-4 p-0.5 inline-block" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                    </span>
                </div>
                    
                <h3 class="text-xl text-left font-semibold tracking-tight text-gray-900 dark:text-white">{{ analysis.users }}</h3>                
            </div>

            <div class="min-w-sm p-4 my-5 mx-auto md:my-5 bg-white rounded-lg shadow-md">                
                <div class="flex items-center justify-between mb-2">
                    <span class="text-md font-normal text-brown-default">Restaurants</span>
                    <span class="bg-red-100 text-brown-default text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-16">
                        <svg class="w-4 h-4 p-0.5 inline-block" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 2V22H19V15H15V8C15 6.4087 15.6321 4.88258 16.7574 3.75736C17.8826 2.63214 19.4087 2 21 2ZM19 4.53C18.17 5 17 6.17 17 8V13H19V4.53ZM9 13.9V22H7V13.9C5.87081 13.6691 4.85599 13.0554 4.12714 12.1625C3.3983 11.2697 3.00014 10.1526 3 9V3H5V10H7V3H9V10H11V3H13V9C12.9999 10.1526 12.6017 11.2697 11.8729 12.1625C11.144 13.0554 10.1292 13.6691 9 13.9Z"/></svg>
                    </span>
                </div>
                    
                <h3 class="text-xl text-left font-semibold tracking-tight text-gray-900 dark:text-white">{{ analysis.vendors }}</h3>                
            </div>

            <div class="min-w-sm p-4 my-5 mx-auto md:my-5 bg-white rounded-lg shadow-md">                
                <div class="flex items-center justify-between mb-2">
                    <span class="text-md font-normal text-brown-default">Orders</span>
                    <span class="bg-red-100 text-brown-default text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-16">
                        <svg class="w-4 h-4 p-0.5 inline-block" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                    </span>
                </div>
                    
                <h3 class="text-xl text-left font-semibold tracking-tight text-gray-900 dark:text-white">{{ analysis.orders }}</h3>                
            </div>

            <div class="min-w-sm p-4 my-5 mx-auto md:my-5 bg-white rounded-lg shadow-md">                
                <div class="flex items-center justify-between mb-2">
                    <span class="text-md font-normal text-brown-default">Revenue</span>
                    <span class="bg-red-100 text-brown-default text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-16">
                        <svg class="w-4 h-4 p-0.5 inline-block" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                    </span>
                </div>
                    
                <h3 class="text-xl text-left font-semibold tracking-tight text-gray-900 dark:text-white"> <span v-if="analysis.revenue" class="line-through">N</span> {{ analysis.revenue }} </h3>                
            </div>
        </div>

        <div></div>
    </div>
</template>

<script>
import TopSearch from '@/components/TopSearch.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        TopSearch
    },
    created() {
        this.getAnalysis();
    },
    computed: {
        ...mapGetters({
            analysis: 'authModule/getAnalysis',
            status: 'authModule/authStatus',
        }),
        // ...mapActions({
        //     fetchAnalysis: 'authModule/analysis',
        // }),
    },

    methods: {
        getAnalysis() {
            // window.location.replace('/admin/dashboard')
            this.$store.dispatch('authModule/analysis')
        }
    }
}
</script>